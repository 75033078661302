'use strict'

###*
 # @ngdoc object
 # @name mundoNavigation
 # @description

###
angular
  .module 'mundoNavigation', []
